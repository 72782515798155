import { styled, Typography, media, selectTheme } from '@creditas-ui/react'

export const Wrapper = styled.section`
  width: 100%;
  background-color: ${selectTheme('colors.neutral.10')};
`
export const Container = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  grid-template-areas:
    'label'
    'cards'
  ${media.up('5xl')} {
    grid-row-gap: 64px;
  }
  ${media.between('5xl', '8xl')} {
    margin: 0 71px;
  }
`
export const TitleLabel = styled(Typography)`
  padding: 48px 20px 32px;
  ${media.up('5xl')} {
    padding: 108px 0 76px;
    max-width: 45%;
  }
`
export const CardContainer = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  ${media.up('5xl')} {
    width: calc(100% + 24px);
    padding: 0 0 136px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${media.between('2xl', '5xl')} {
    padding-bottom: 96px;
  }
`
export const StepBox = styled.div`
  display: flex;
  &:first-of-type {
    .first-dash {
      background-color: transparent;
    }
  }
  &:last-of-type {
    .last-dash {
      background-color: transparent;
    }
  }
  ${media.up('5xl')} {
    flex-direction: column;
    height: 292px;
  }
`
export const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 44px;
  .dot {
    border-radius: 50%;
    background-color: ${selectTheme('colors.primary.40')};
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-dash {
    width: 2px;
    height: 60%;
    background-color: ${selectTheme('colors.primary.40')};
  }
  .last-dash {
    width: 2px;
    height: 100%;
    background-color: ${selectTheme('colors.primary.40')};
  }
  ${media.up('5xl')} {
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 40px;
    height: 40px;
    .first-dash,
    .last-dash {
      width: 100%;
      height: 2px;
      background-color: ${selectTheme('colors.primary.40')};
    }
  }
`
export const Card = styled.div`
  max-width: 392px;
  height: 212px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0 28px 64px rgba(31, 45, 39, 0.16);
  border-radius: 24px;
  background-color: ${selectTheme('colors.neutral.0')};
  margin-bottom: 56px;
  ${media.up('5xl')} {
    margin-bottom: 0;
    margin-right: 24px;
  }
`

export const Subtitle = styled(Typography)`
  margin: 16px 0 12px;
  ${media.up('5xl')} {
    margin-top: 32px;
  }
`
