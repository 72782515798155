import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas-ui/react'
import { Cash, Pencil, Headset } from 'icons'
import {
  Wrapper,
  Container,
  TitleLabel,
  CardContainer,
  Subtitle,
  StepBox,
  Step,
  Card,
} from './steps.styles'

const icons = [Pencil, Headset, Cash]

const Steps = () => {
  const { t } = useTranslation()
  const labels = t('commons:steps', { returnObjects: true })
  const zero = t('autoFin:steps:zero')
  return (
    <Wrapper>
      <Container>
        <TitleLabel
          color="neutral.90"
          component="h2"
          variant="headingLgLight"
          dangerouslySetInnerHTML={{ __html: labels.title }}
        />
        <CardContainer>
          {labels.cards.map((card, index) => {
            return (
              <StepBox key={card.subtitle}>
                <Step>
                  <figure className="first-dash" />
                  <div className="dot">
                    <Typography
                      color="neutral.90"
                      component="span"
                      variant="bodyLgBold"
                    >
                      {zero}
                      {index + 1}
                    </Typography>
                  </div>
                  <figure className="last-dash" />
                </Step>
                <Card>
                  {icons[index]()}
                  <Subtitle
                    color="neutral.90"
                    component="h3"
                    variant="headingSmBold"
                  >
                    {card.subtitle}
                  </Subtitle>
                  <Typography
                    color="neutral.80"
                    component="p"
                    variant="bodyLgRegular"
                  >
                    {card.description}
                  </Typography>
                </Card>
              </StepBox>
            )
          })}
        </CardContainer>
      </Container>
    </Wrapper>
  )
}

export default Steps
