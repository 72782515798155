import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Typography } from '@creditas-ui/react'
import { Cash, Pencil, Headset } from 'icons'
import {
  Card,
  CardContainer,
  Container,
  IconContainer,
  ImageContainer,
  Step,
  StepBox,
  TitleLabel,
  Wrapper,
} from './steps.styles'

const propTypes = {
  altText: string,
  imageDesktop: string,
  imageMobileOrTablet: string,
}

const icons = [Pencil, Headset, Cash]

const Steps = ({ altText, imageDesktop, imageMobileOrTablet }) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery(
    { maxDeviceWidth: 1024 },
    { deviceWidth: 1024 },
  )
  const labels = t('commons:steps', { returnObjects: true })
  const zero = t('autoFin:steps:zero')
  return (
    <Wrapper>
      <Container>
        <TitleLabel
          color="neutral.90"
          component="h2"
          variant="headingLgLight"
          dangerouslySetInnerHTML={{ __html: labels.title }}
        />
        <CardContainer>
          {labels.cards.map((card, index) => {
            const numberDot = `${index + 1}. `
            return (
              <StepBox key={card.subtitle}>
                <Step>
                  <div className="first-dash" />
                  <div className="dot">
                    <Typography
                      color="neutral.90"
                      component="span"
                      variant="bodyLgBold"
                    >
                      {zero}
                      {index + 1}
                    </Typography>
                  </div>
                  <div className="last-dash" />
                </Step>
                <Card>
                  <IconContainer className="icons-tablet">
                    {icons[index]()}
                  </IconContainer>
                  <Typography
                    color="neutral.90"
                    component="h3"
                    variant="headingXsBold"
                  >
                    <span>{numberDot}</span>
                    {card.subtitle}
                  </Typography>
                  <Typography
                    color="neutral.80"
                    component="p"
                    variant="bodyLgRegular"
                  >
                    {card.description}
                  </Typography>
                </Card>
              </StepBox>
            )
          })}
        </CardContainer>
        <ImageContainer
          src={isMobileOrTablet ? imageMobileOrTablet : imageDesktop}
          aspectRatio={1}
          alt={altText}
        />
      </Container>
    </Wrapper>
  )
}

Steps.propTypes = propTypes

export default Steps
